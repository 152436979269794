// Variables
$one:  1 0 100%; $two: 1 0 50%; $three: 1 0 33.3%; $four: 1 0 25%; $five: 1 0 20%;
$gutter: 60px;
$halfgutter: $gutter / 2;

$darknavy: #000000;
$red: #EB5757;
$grey: #6D6D6D;

$col1: #000000;

// Reset
* { margin: 0; padding:0; box-sizing: border-box; -webkit-font-smoothing: antialiased; }
body { overflow-x: hidden; }
a { color: black; text-decoration: none; }
::-moz-selection { background: salmon; }
::selection { background: salmon; }



// Mixins
@mixin phone { @media (min-width: 320px) and (max-width: 767px )  { @content; } }
@mixin tablet { @media (min-width: 768px) { @content; } }
@mixin laptop { @media (min-width: 1024px) { @content; } }
@mixin desktop { @media (min-width:1280px) { @content; } }
@mixin super { @media (min-width: 1700px) { @content; } }

@mixin prop($property, $phoneval, $tabletval, $laptopval, $deskval, $superval) {
  @include phone { #{$property}: #{$phoneval}; }
  @include tablet { #{$property}: #{$tabletval}; }
  @include laptop  { #{$property}: #{$laptopval}; }
  @include desktop  { #{$property}: #{$deskval}; }
  @include super { #{$property}: #{$superval}; }
}




// Typography
@font-face { font-family: 'Albertus Medium'; src: url('../fonts/Albertus-Medium.woff'); font-weight: 400; }

* { font: 17px/23px 'Albertus Medium', serif; letter-spacing: .2px; }
strong, b { font-weight: 400; font-family: 'Albertus Medium', sans-serif; }

$serif: 'Albertus Medium', serif;

h1, h2, h3, h4, h5 {
  font-family: $serif;
}
h1, h2, h3 { font: 17px/23px 'Albertus Medium', serif; }



// Grid 
@mixin one { flex: $one; max-width: 100%; }
@mixin two { flex: $two; max-width: 50%; }
@mixin three { flex: $three; max-width: 33.3%; }
@mixin four { flex: $four; max-width: 25%; }
@mixin five { flex: $five; max-width: 20%; }

body {
  transition: all ease .3s;
}

body.light {
  .logo span, div.slides div.slide div.caption, header, nav ul li.work a, nav ul li.info a, nav ul li.publication a {
      color: white;
  }
  nav ul li.work a:before, nav ul li.info a:before, nav ul li.publication a:before {
    background: white;
  }

  #work {
    .fullscreen {
      cursor: url('../images/zoom-w.png'), auto;
    }
    .left {
      cursor: url('../images/left-w.png'), auto;
    }
    .right {
      cursor: url('../images/right-w.png'), auto;
    }
    .slide.active {
      cursor: url('../images/close-w.png'), auto;
    }
  }
}

body.out {
  opacity: 0;
  pointer-events: none;
}

body.zoom {
  header {
    pointer-events: none !important;
  }
  #work {
    .left, .right, .fullscreen {
      pointer-events: none !important;
    }
  }
}

body.mobile {
  * {
    font: 14px/20px 'Albertus Medium', serif;
  }
  section.home {
    height: 70vh;
    background-size: cover;
    background-position: center center;
  }
  header {
    padding: 20px 40px;
    li.work, li.info, li.publication {
      position: relative;
      top: inherit;
      display: inline-block;
      right: inherit;
    //   opacity: 0;
      left: inherit;
      width: inherit;
      height: inherit;
      padding: 0;
      padding-left: 10px;
      transition: all ease .3s;
    //   &.visible {
    //     opacity: 1;
    //   }
    }
  }
  #work {
    position: absolute;
    .left {
      position: fixed;
      top: 50%;
      left: 0px;
      opacity: 1;
      width: 40px;
      height: 40px; 
      background-color: transparent;
      background-image: url('../images/left.png');
      background-repeat: no-repeat;
      background-position: center center;
      background-size: 40%;

    }
    .right {
      position: fixed;
      top: 50%;
      right: 0px;
      opacity: 1;
      width: 40px;
      height: 40px;
      left: inherit;
      background-color: transparent;
      background-image: url('../images/right.png');
      background-repeat: no-repeat;
      background-position: center center;
      background-size: 40%;
    }
    .fullscreen {
      pointer-events: none;
    }
    .slide {
      left: 40px;
      top: 60px;
      width: calc(100% - 80px);
      height: calc(100% - 80px);
      @include tablet {
        cursor: inherit;
      }
      .caption {
        bottom: 30px;
        p, strong {
          font-size: 13px;
          line-height: 18px;
        }
        @include tablet {
          bottom: 10px;
        }
      }
      .photo {
        height: 75%;
        @include tablet {
          height: 85%;
          left: 50%;
          transform: translateX(-50%);
          width: calc(100% - 100px);
        }
      }
    }
  }
  #info {
    padding: 30px 60px 30px 30px;
    @include tablet {
      width: 100%;
      padding: 30px 200px 30px 30px;
    }
    .content {
      padding-top: 80px;
      .dds {
        margin-left: 0px;
        margin-top: 0px;
        li > a {
          // margin-top: 
        }
        .dd li strong {
          position: inherit;
          margin-left: inherit;
          max-width: inherit;
          margin-right: 20px;
          display: block;
        }
      }
    }
  }
  #publication {
    padding: 30px 60px 30px 30px;
    @include tablet {
      width: 100%;
      padding: 30px 200px 30px 30px;
    }
    .content {
      padding-top: 80px;
      img {
        width: 100%;
        padding-top: 30px;
      }
    }
  }
}


header {
  position: relative;
  padding: $gutter;
  z-index: 4;
  .logo {
    position: relative;
    display: inline-block;
    z-index: 2;
    transition: all ease .3s;
    cursor: pointer;
    &.light {
      span {
        color: white;
      }
    }
    span {       
      transition: all ease .3s;
      opacity: 1;
    }
    span.show {
      opacity: 1;
    }
    &.shorthand {
      span {
        opacity: 0;
      }
      span.show {
        opacity: 1;
      }
    }
  }

  nav {
    li.info, li.work, li.publication {
      transition: all ease .3s;
    }
    &.hidden {
      li.info, li.work {
        // transform: translateY('0px');
        // opacity: 0;
      }
    }
  }

  li.work {
    position: absolute;
    top: $gutter;
    z-index: 2;
    @include prop('left', 30px, 20vw, 20vw, 20vw, 25vw);
    // opacity: 0;
    a {
      position: relative;
      &:before {
        content: ' ';
        position: absolute;
        width: 4px;
        height: 4px;
        border-radius: 2px;
        left: 50%;
        bottom: -10px;
        background: black;
        opacity: 0;
      }
    }
    &.active {
      a {
        &:before {
          opacity: 1;
        } 
      }
    }
    &.visible {
      opacity: 1;
    }
    &.light {
      a {
        color: white;
        &:before {
          background: white;
        }
      }
    }
  }

  li.info {
    position: absolute;
    top: 0px;
    padding-top: $gutter;
    padding-bottom: 35px;
    background: transparent;
    width: 100%; 
    z-index: 3;
    transition: all ease .3s;
    @include prop('left', 30px, 25vw, 25vw, 40vw, 50vw);
    @include prop('padding-left', $gutter*1.5, $gutter*2.5, $gutter*2.5, $gutter*2.5, $gutter*2.5);
    // opacity: 0;
    a {
      position: relative;
      color: $col1;
      &:before {
        content: ' ';
        position: absolute;
        width: 4px;
        height: 4px;
        border-radius: 2px;
        left: 40%;
        bottom: -10px;
        background: black;
        opacity: 0;
      }
    } 
    &.active {
      a:before {
        opacity: 1;
      }
    }
    &.visible {
      opacity: 1;
    }
    &.light {
      a {
        color: white;
        &:before {
          background: white;
        }
      }
    }
  }

  li.publication {
    position: absolute;
    top: 0px;
    padding-top: $gutter;
    padding-bottom: 35px;
    background: transparent;
    // width: 100%; 
    z-index: 3;
    transition: all ease .3s;
    @include prop('right', 30px, 60px, 60px, 60px, 60px);
    // @include prop('padding-left', $gutter*1.5, $gutter*2.5, $gutter*2.5, $gutter*2.5, $gutter*2.5);
    // opacity: 0;
    a {
      position: relative;
      color: $col1;
      &:before {
        content: ' ';
        position: absolute;
        width: 4px;
        height: 4px;
        border-radius: 2px;
        left: 40%;
        bottom: -10px;
        background: black;
        opacity: 0;
      }
    } 
    &.active {
      a:before {
        opacity: 1;
      }
    }
    &.visible {
      opacity: 1;
    }
    &.light {
      a {
        color: black;
        &:before {
          background: black;
        }
      }
    }
  }

  nav {
    display: inline-block;
    ul {
    }
    li {
      list-style-type: none;
      display: inline-block;
      a {

      }
    }
  }
  
}


.home {
  // background-color: red;
  position: fixed;
  width: 100vw;
  height: calc(100vh - 145px);
  bottom: 0px;
  background-repeat: no-repeat;
  background-position: left bottom;
  background-size: contain;

  &.right {
    background-position: right bottom;
  }

}

#sidebar {
  position: fixed;
  z-index: 3; 
  top: 50%;
  right: 0px;
  margin-right: -90px;
  text-align: center;
  transform-origin: 50% 53%;
  transform: rotate(90deg) translateZ(0px);
  transition: all .3s ease .1s;
  pointer-events: auto;
  &.hidden {
    pointer-events: none;
    opacity: 0;
    transform: rotate(90deg) translateY(-10px);
  }
  a {
    padding: 5px 0px 0px;
    margin: 0 10px;
    color: white;
    border-bottom: 1px solid white;
  }
}


#info {
  position: fixed;
  top: 0px;
  right: 0px;
  width: 100vw;
  height: 100vh;
  overflow-y: scroll;
  background: white;
  z-index: 2;
  color: white;
  padding: $gutter/2;
  transition: all ease .3s;
  // transform: translateX(2%);
  opacity: 0;
  pointer-events: none;
  .content > p, .content > .dds > li {
    transition: all cubic-bezier(0.23, 1, 0.32, 1) 1s;
    margin-top: 30px;
  }
  &.active {
    transform: translateX(0%);
    opacity: 1;
    pointer-events: auto;
    .content > p, .dds > li {
      margin-top: 0px;
    }
  }

  @include tablet {
    width: 75vw;
    padding: 0 $gutter*4 $gutter $gutter;
  }


  @include desktop {
    width: 60vw;
    padding: 0 $gutter*4 $gutter $gutter;
  }

  a {
    color: white;
  }

  & > .content {
    padding-top: $gutter*2;
    h2 {
      margin: $gutter*.6 0;
      text-indent: $gutter*1.5;
    }
    & > p {
      text-indent: $gutter*1.5;
    }
    ul.dds {
      margin: $gutter*.6 0px 0px $gutter*1.5;
      li { list-style-type: none;
        & > a {
          display: inline-block;
          margin: $gutter*.6 0 $gutter*.2;
          border-bottom: 1px solid white;
          // margin-bottom: $gutter*.6;
        }
      }
      
      .dd {
        height: 0px;
        overflow: hidden;
        opacity: 0;
        transition: all ease .3s;
        &.open {
          // height: auto;
          opacity: 1;
        }
        li {
          margin: $gutter*.4 0;
        }
        li, p {
          a {
            margin-top: $gutter;
          }
          strong {
            position: absolute;
            margin-left: -$gutter*1.5;
            max-width: $gutter;
          }
        }
      }
    }
  }
}

#work {
  position: fixed;
  top: 0px;
  right: 0px; 
  width: 100vw;
  height: 100vh;
  pointer-events: none !important;
  overflow: hidden;
  background: transparent;
  z-index: 1;
  opacity: 0;
  transition: all ease .4s;
  .slide.active {
    pointer-events: none !important;
  }
  &.active {
    pointer-events: auto;
    opacity: 1;
    .left, .right, .fullscreen {
      pointer-events: auto;
    }
    .slide.active {
      pointer-events: auto !important;
    }
  }
  .left, .right {
    position: absolute;
    width: 30vw;
    height: 100vw;
    left: 0px;
    background: tomato;
    z-index: 2;
    opacity: 0;
    pointer-events: none;
    // border: 2px solid white;
  }
  .left {
    left: 0px;
    cursor: url('../images/left.png'), auto;
  }
  .right {
    left: 70vw;
    // background: blue;
    cursor: url('../images/right.png'), auto;
  }
  .fullscreen {
    position: absolute;
    width: 40vw;
    height: 100vh;
    background: red;
    opacity: 0;
    z-index: 2;
    pointer-events: none;
    left: 30vw;
    top: 0px;
    cursor: url('../images/zoom.png'), auto;
  }

  .slide {
    position: absolute;
    display: block;
    left: $gutter;
    top: $gutter * 2;
    width: calc(100% - #{$gutter*2});
    height: calc(100% - #{$gutter*3});
    // overflow: hidden;
    transition: all ease .3s;
    pointer-events: none;
    opacity: 0;  

    .caption {
      position: absolute;
      left: 0px;
      bottom: 0px;
      padding-left: $gutter / 1.5;
      transition: all ease .3s;
      z-index: 1;
      max-width: 190px;
      p, strong {
        font-size: 14px;
        line-height: 20px;
      }
      strong {
        display: block;
        text-indent: -($gutter / 1.5);
      }
    }

    .photo {
      position: absolute;
      width: calc(100% - 400px);
      height: 100%;
      left: 200px;
      background-size: contain;
      background-repeat: no-repeat;
      background-color: transparent;
      background-position: 50% 50%;
      transition: transform ease .5s;
      @include phone {
        width: 100%;
        left: 0px;
      }
    }

    &.active {
      pointer-events: auto;
      opacity: 1;
      cursor: url('../images/close.png'), auto;
    }
    &.zoomed {
      width: 100%;
      height: 100%;
      left: 0;
      top: 0;
      .caption {
        opacity: 0;
      }
    }
  }
}



#publication {
  position: fixed;
  top: 0px;
  right: 0px;
  width: 100vw;
  height: 100vh;
  overflow-y: scroll;
  background: white;
  z-index: 2;
  color: black;
  padding: $gutter/2;
  transition: all ease .3s;
  // transform: translateX(2%);
  opacity: 0;
  pointer-events: none;
  .content {
    p {
      transition: all cubic-bezier(0.23, 1, 0.32, 1) 1s;
      margin-top: 30px;
    }
    a {
      border-bottom: 1px solid black;
    }
    img {
      width: 100%;
      padding-top: 30px;
    }
  }
  &.active {
    transform: translateX(0%);
    opacity: 1;
    pointer-events: auto;
    .content > p {
      margin-top: 0px;
    }
  }

  @include tablet {
    width: 75vw;
    padding: 0 $gutter*4 $gutter $gutter;
  }

  @include desktop {
    width: 60vw;
    padding: 0 $gutter*4 $gutter $gutter;
  }

  a {
    color: black;
  }

  & > .content {
    padding-top: $gutter*2;
    h2 {
      margin: $gutter*.6 0;
      text-indent: $gutter*1.5;
    }
    & > p {
      text-indent: $gutter*1.5;
    }
  }
}

// States
.infomodal {
  @include tablet {
    header li.publication {
      opacity: 0;
    }
  }
}

.publicationmodal {
  &.mobile header {
    a span {
      color: black; 
    }
    nav ul li a {
      color: black;
      &:before {
        background: black;
      }
    }
  }
  @include tablet {
    header li.info {
      opacity: 0;
    }
    #sidebar {
      a {
        color: black;
        border-bottom: 1px solid black;
      }
    }
  }
}